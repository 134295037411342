import { useEffect, useState } from "react";
import Api from "../../../../../../../helper/Api";
import { RequestVideoInterviewForm } from "./requestVideoInterviewForm";
import { toast } from "react-toastify";
import { validations } from "../../../../../../../helper/validations";
import { EclipseLoader } from "../../../../../eclipseLoader";
import { EditButton } from "../../../../../../ui/button/edit";
import { SendButton } from "../../../../../../ui/button/send";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { IconButton, Rating, Tooltip } from "@mui/material";
import { RatingIcon } from "../../../../../../../icons/rating";
import moment from "moment";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";
import { VIDEO_DATA_ACTIONS } from "../../../../../../../redux/videoInterviewData/actions";
import { ReviewerForm } from "./reviewerForm";
import { EditVideoInterview } from "./editVI";
import { SendVINotification } from "./sendForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

const VIDEO_INTERVIEW_STATUS = {
  Completed: "d-flex status green-active",
  "In progress": "d-flex status-label yellow",
  "In Progress": "d-flex status-label yellow",
  Pending: "d-flex status red-delete",
  Canceled: "d-flex status",
  Viewed: "d-flex status-label orange"
};

export const VideoInterviewStatus = ({
  setResendInterviewForm,
  formValues,
  setFormValues,
  selectedSuitCase,
  resendForm,
  resendInterviewForm,
  applicantData,
}) => {
  const [videoInterviewList, setVideoInterviewList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [requestInterview, setRequestInterview] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openSendForm, setOpenSendForm] = useState(false);
  const [openReviewerForm, setReviewerForm] = useState(false);
  const [connectionString, setConnection] = useState(null);
  const [edit, setEdit] = useState(false);
  const [vInterview, setInterview] = useState({});

  const dispatch = useDispatch();
  const BASEURL = process.env.REACT_APP_BASE_URL;
  const errors = {};
  const { videosArray } = useSelector(
    (state) => state.videoInterviewDataReducer
  );

  useEffect(() => {
    setLoading(true);
    getVideoInterviewList();
  }, []);

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("UpdatedVIList", (res) => {
      res.map((r) => {
        if (r.applicantId === applicantData.applicantId) {
          setVideoInterviewList(res);
        }
      });
    });

    await connect.start();

  }, [selectedSuitCase.jobId, videoInterviewList]);

  const toggleDrawer = (open) => {
    setFormErrors({});
    setRequestInterview(open);
  };

  const toggleSelectReviewer = (open) => {
    setReviewerForm(open);
  };

  // Send VI notification form
  const toggleSendForm = (open) => {
    setOpenSendForm(open);
  };

  const processVideoInterviewData = (videoInterviewList) => {
    const reviewersData = {};
    let totalRatings = 0;
    let totalUsers = 0;

    videoInterviewList.forEach((interview) => {
      const { videoQuestionsList } = interview;

      if (
        interview.videoInterviewStatus === "Completed" ||
        interview.videoInterviewStatus === "Complete"
      ) {
        videoQuestionsList.forEach((question) => {
          const { videoQuestionStatus, reviewers } = question;

          if (videoQuestionStatus === "Completed") {
            reviewers.forEach((reviewer) => {
              const { userId, firstName, lastName, reviewerStatus, rating } = reviewer;

              if (!reviewersData[userId]) {
                reviewersData[userId] = {
                  firstName,
                  lastName,
                  ratings: [rating],
                  reviewerStatus,
                  count: 1,
                };
              } else {
                reviewersData[userId].ratings.push(rating);
                reviewersData[userId].count += 1;
              }

              totalRatings += rating;
              totalUsers += 1;
            });
          }
        });
      }
    });

    const averages = {};
    let mainAverageRating = 0;
    if (totalUsers > 0) {
      mainAverageRating = (totalRatings / totalUsers).toFixed(1);
    }

    Object.keys(reviewersData).forEach((userId) => {
      const { ratings, count } = reviewersData[userId];
      const averageRating = ratings.reduce((acc, curr) => acc + curr, 0) / count;
      averages[userId] = {
        firstName: reviewersData[userId].firstName,
        lastName: reviewersData[userId].lastName,
        reviewerStatus: reviewersData[userId].reviewerStatus,
        averageRating: averageRating.toFixed(1),
      };

    });
    return { averages, mainAverageRating };
  };


  const getVideoInterviewList = () => {
    Api(
      "GET",
      `Job/GetRequestVideoInterviewList?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setVideoInterviewList(res.data);
      })
      .finally(() => setLoading(false));
  };

  const deriveOverallUserStatus = (interview) => {
    const { videoQuestionsList } = interview;
    const userStatuses = {};

    videoQuestionsList.forEach((question) => {
      question.reviewers.forEach((reviewer) => {
        const { userId, reviewerStatus, firstName, lastName } = reviewer;
        if (!userStatuses[userId]) {
          userStatuses[userId] = { status: [reviewerStatus], firstName, lastName };
        } else {
          userStatuses[userId].status.push(reviewerStatus);
        }
      });
    });

    const userOverallStatus = {};

    Object.keys(userStatuses).forEach((userId) => {
      const { status, firstName, lastName } = userStatuses[userId];

      if (status.includes('Completed')) {
        userOverallStatus[userId] = { status: 'Completed', firstName, lastName };
      }
      else if (status.includes('Completed') && (status.length === 1 || !status.some(Boolean))) {
        userOverallStatus[userId] = { status: 'In progress', firstName, lastName };
      } else if (status.every((s) => s === 'Pending')) {
        userOverallStatus[userId] = { status: 'Pending', firstName, lastName };
      } else if (status.every((s) => s === 'Viewed')) {
        userOverallStatus[userId] = { status: 'Viewed', firstName, lastName };
      } else if (status.includes('Completed') && status.includes('Pending')) {
        userOverallStatus[userId] = { status: 'In progress', firstName, lastName };
      } else if (status.includes('Completed') && status.includes('Viewed')) {
        userOverallStatus[userId] = { status: 'In progress', firstName, lastName };
      } else if (status.every((s) => s === 'Completed')) {
        userOverallStatus[userId] = { status: 'Completed', firstName, lastName };
      } else {
        userOverallStatus[userId] = { status: 'In progress', firstName, lastName };
      }
    });

    return userOverallStatus;
  };

  function handleRequestVideInterview() {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      applicantId: applicantData.applicantId,
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      videoInterviewId: formValues.videoInterviewId,
      description: formValues.description,
      expDate: formValues.expDate,
    };

    setSubmitting(true);

    Api("POST", "Job/RequestVideoInterview", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer(false);
          getVideoInterviewList();
        } else {
          toast.error(res.data.message);
          toggleDrawer(true);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  // Validations
  const validate = (values) => {
    if (!values.videoInterviewId) {
      errors.videoInterviewId = validations?.videoInterviewId?.req;
    }

    return errors;
  };

  const deleteInterview = (interview) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      // DELETE Video Interview api call
      Api(
        "DELETE",
        `Job/DeleteRequestVideoInterview?jobId=${interview.jobId}&companyId=${interview.companyId}&applicantId=${interview.applicantId}&jobApplicantVideoInterviewId=${interview.jobApplicantVideoInterviewId}`
      ).then((res) => {
        if (res.data.isSuccess === true) {
          showSuccess();
          getVideoInterviewList();
        } else {
          toast.error(res.data.message);
        }
      });
    });
  };

  const openVideoInterviews = (interview) => {
    interview.applicantName = applicantData.name;
    const data = [...videosArray, interview];

    const isPresent = videosArray.some((a) => {
      return (
        a.jobApplicantVideoInterviewId ===
        interview.jobApplicantVideoInterviewId
      );
    });

    if (isPresent === false) {
      dispatch({
        type: VIDEO_DATA_ACTIONS.SET_VIDEOS_ARRAY,
        payload: data,
      });
    }
  };

  // Edit video interview form
  const toggleEditDrawer = (open) => {
    setOpenEditForm(open);
  };

  const saveReviewer = () => {

    const updatedFormValues = { ...formValues }; // Clone formValues
    updatedFormValues.videoInterviewId = vInterview.jobApplicantVideoInterviewId;
    updatedFormValues.reviewerList = [] // You can set reviewerList here based on your form data

    updatedFormValues.reviewerList = formValues.reviewerList; // Replace reviewerDetails with your actual form data

    setFormValues(updatedFormValues); // Update form values with the updated reviewerList

    setFormErrors(validate(updatedFormValues));
    if (Object.keys(validate(updatedFormValues)).length > 0) {
      return;
    }

    const params = {
      jobApplicantVideoInterviewId: vInterview.jobApplicantVideoInterviewId,
      reviewerListIds: formValues.reviewerListIds,
      reviewerList: formValues.reviewerList // Include reviewerList in the API call
    };

    setSubmitting(true);
    Api("POST", `Job/SaveVideoInterviewReviewer`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSelectReviewer(false);
          getVideoInterviewList()
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSendNotification = (expDate, description) => {
    setSubmitting(true);
    const params = {
      companyId: vInterview.companyId,
      jobId: vInterview.jobId,
      jobApplicantVideoInterviewId: vInterview.jobApplicantVideoInterviewId,
      applicantId: vInterview.applicantId,
      description: description,
      expireDate: expDate,
    };

    Api("PUT", `Job/ResendNotification`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          toggleSendForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleSubmit = (expireDate) => {
    const params = {
      companyId: vInterview.companyId,
      jobId: vInterview.jobId,
      jobApplicantVideoInterviewId: vInterview.jobApplicantVideoInterviewId,
      applicantId: vInterview.applicantId,
      expireDate: expireDate,
    };

    setSubmitting(true);
    Api("PUT", `Job/EditRequestVideoInterview`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          toggleEditDrawer(false);
          getVideoInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {openReviewerForm === true && (
        <ReviewerForm
          openForm={openReviewerForm}
          toggleForm={toggleSelectReviewer}
          applicantId={applicantData.applicantId}
          formValues={formValues}
          setFormValues={setFormValues}
          childCompanyId={applicantData.companyId}
          jobApplicantVideoInterviewId={vInterview.jobApplicantVideoInterviewId}
          saveReviewer={saveReviewer}
          formErrors={formErrors}
          isSubmitting={isSubmitting}
          setFormErrors={setFormErrors}
        />
      )}

      {openEditForm && (
        <EditVideoInterview
          toggleDrawer={toggleEditDrawer}
          applicantId={applicantData.applicantId}
          openSidebar={openEditForm}
          handleSubmit={handleSubmit}
          expDate={vInterview?.expireDate}
          isSubmitting={isSubmitting}
        />
      )}

      {openSendForm && (
        <SendVINotification
          toggleForm={toggleSendForm}
          applicantId={applicantData.applicantId}
          openSidebar={openSendForm}
          handleSubmit={handleSendNotification}
          isSubmitting={isSubmitting}
          expDate={vInterview?.expireDate}
        />
      )}

      <div>
        <p style={{ fontWeight: 600, marginBottom: 10, fontSize: 14 }}>
          Video Interview :
        </p>
      </div>

      {isLoading ? (
        <EclipseLoader />
      ) : (
        <>
          <button
            className="add-btn manage-btn job video"
            onClick={() => (
              setResendInterviewForm(false),
              setEdit(false),
              toggleDrawer(true),
              setFormValues({})
            )}
          >
            Request Video Interview
            <img
              src={require("../../../../../../../assets/images/Vector.png")}
              alt="right-arrow"
              className="right-arrow-btn"
            />
          </button>

          {requestInterview === true && (
            <RequestVideoInterviewForm
              openForm={requestInterview}
              toggleForm={toggleDrawer}
              resendForm={resendForm}
              resendInterviewForm={resendInterviewForm}
              edit={edit}
              handleRequestVideInterview={handleRequestVideInterview}
              formValues={formValues}
              setFormValues={setFormValues}
              formErrors={formErrors}
              applicantId={applicantData.applicantId}
              isSubmitting={isSubmitting}
            />
          )}

          {videoInterviewList.map((interview) => {
            const { averages = {}, mainAverageRating = 0 } = processVideoInterviewData([interview]);

            return (
              <div>
                <strong>{interview.viName}</strong>
                <div className="profile-data pointer mt-10 flex-row align-items-center justify-content-between">
                  {interview.videoInterviewStatus === 'Completed' && (
                    <video
                      className="video-list-thumbnail pointer"
                      height={'70px'}
                      key={interview.videoQuestionsList[0].videoQuestionId}
                      alt={interview.videoQuestionsList[0].videoQuestionId}
                      onClick={() => openVideoInterviews(interview)}
                    >
                      <source
                        src={'https://' + interview.videoQuestionsList[0].mediaFile?.slice(6)}
                        type="video/mp4"
                      />
                    </video>
                  )}

                  <div className="v-rating pointer">
                    <span
                      className={VIDEO_INTERVIEW_STATUS[interview.videoInterviewStatus]}
                      onClick={() => openVideoInterviews(interview)}
                    >
                      {interview?.videoInterviewStatus}
                    </span>
                    <span
                      className="font-12 mt-10 pointer"
                      onClick={() => openVideoInterviews(interview)}
                    >
                      <div>
                        <label>Requested : </label>
                        {moment(interview?.requestedDate).format('l')} &nbsp;
                      </div>
                      {interview?.completedDate !== null && (
                        <div>
                          <label>Expires : </label>
                          {moment(interview?.expireDate).format('l')}
                        </div>
                      )}
                    </span>
                  </div>
                  {interview.videoInterviewStatus === 'Completed' && (
                    <>
                      <Tooltip title="Ratings">
                        <IconButton
                          onClick={() => (
                            toggleSelectReviewer(true),
                            setInterview(interview)
                          )}
                        >
                          <RatingIcon width="26" height="26" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>



                <div key={interview.jobApplicantVideoInterviewId}>

                  {interview.videoInterviewStatus === 'Completed' && (
                    <>
                      {/* Destructure values from processVideoInterviewData */}

                      <div className="font-12">
                        <div className="d-flex align-items-center">
                          <strong>Average Rating :</strong>
                          <Rating
                            name="read-only"
                            value={mainAverageRating}
                            readOnly
                            precision={0.5}
                          />
                        </div>
                        <div>
                          <strong>Reviewer:</strong>
                          {Object.keys(deriveOverallUserStatus(interview)).map((userId) => {
                            const userStatus = deriveOverallUserStatus(interview)[userId];
                            return (
                              <div key={userId} className="d-flex align-items-center mb-2 mt-5">
                                <div className="mr-10">
                                  {averages[userId]?.firstName + " " + averages[userId]?.lastName}
                                  {averages[userId]?.averageRating === "0.0"
                                    ? ""
                                    : `(${averages[userId]?.averageRating})`}
                                </div>
                                <div>
                                  <span
                                    className={
                                      VIDEO_INTERVIEW_STATUS[userStatus.status]
                                    }
                                  >
                                    {userStatus.status}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* Rest of your JSX code */}
                    </>
                  )}
                </div>


                <div className="d-flex">
                  {interview?.videoInterviewStatus !== 'Cancelled' &&
                    interview?.videoInterviewStatus !== 'Canceled' &&
                    interview?.videoInterviewStatus !== 'Complete' &&
                    interview?.videoInterviewStatus !== 'Completed' && (
                      <>
                        <EditButton
                          onClick={() => (
                            toggleEditDrawer(true),
                            setInterview(interview)
                          )}
                        />
                        <SendButton
                          onClick={() => (
                            toggleSendForm(true),
                            setInterview(interview)
                          )}
                        />
                        <DeleteButton
                          title="Cancel"
                          onClick={() => deleteInterview(interview)}
                        />
                      </>
                    )}
                </div>
                <div className="border-bottom"></div>
              </div>
            )
          })}


          {/*  */}

        </>
      )}
    </>
  );
};
