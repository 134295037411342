import { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { indigo } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";
import { FormHelperText } from "@mui/material";

export const AcceptInterviewForm = (props) => {
  const [slots, setSlots] = useState([]);
  const [value, setValue] = useState(props.availableSlots[0]);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    setSlots(props.availableSlots);
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof value == "object") {
      setHelperText("Please select an option.");
      setError(true);
      return;
    } else {
      setError(false);
      props.handleSubmit(props.interScheduleId, value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <AddEditBox
      openForm={props.openForm}
      toggleForm={props.toggleForm}
      title="Accept Interview"
      handleSubmit={handleSubmit}
      applicantId={props.applicantId}
      isSubmitting={props.isSubmitting}
    >
      <FormControl error={error}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          className="ml-10"
        >
          {slots.map((slot, index) => (
            <FormControlLabel
              key={index}
              value={slot.slots}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                  size="small"
                />
              }
              label={moment(slot.slots).format("h:mm a, MMM Do, YYYY")}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </AddEditBox>
  );
};
