import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../../../CheckboxList";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export const ReviewerForm = ({
  openForm,
  toggleForm,
  applicantId,
  formValues,
  setFormValues,
  saveReviewer,
  formErrors,
  jobApplicantVideoInterviewId,
  isSubmitting,
  childCompanyId,
}) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [isLoading, setLoading] = useState(true);
  const [reviewerForms, setReviewerForms] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  // New error states for specific fields
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const errors = {};

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex


  useEffect(() => {
    Api(
      "GET",
      `Job/GetTypeWiseUserList?companyId=${childCompanyId}&type=2`
    )
      .then((res) => {
        const data = [...res.data];
        data.forEach((rec) => {
          rec.id = rec.userId;
          rec.name = rec.userFirstName + " " + rec.userLastName;
          rec.checked = false;
        });

        setAllData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    setFormValues({ ...formValues, jobApplicantVideoInterviewId: jobApplicantVideoInterviewId });

  }, []);
  // console.log(companyData, "companyData")

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    const data = [...checkList];
    const checkedDocs = [];
    data.forEach((rec) => {
      if (rec.checked === true) {
        checkedDocs.push(rec.userId);
      }
    });

    setFormValues({ ...formValues, ["reviewerListIds"]: checkedDocs });
    setAllData(checkList);
  };
  const handleAddReviewer = () => {
    const newForm = {
      firstName: "",
      lastName: "",
      title: "",
      emailAddress: "",
    };
    setReviewerForms([...reviewerForms, newForm]);
  };

  const handleClose = (index) => {
    const updatedForms = [...reviewerForms];
    updatedForms.splice(index, 1);
    setReviewerForms(updatedForms);
  };
  // const handleInputChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const updatedForms = [...reviewerForms];
  //   updatedForms[index] = { ...updatedForms[index], [name]: value };
  //   setReviewerForms(updatedForms);

  //   // Create a new reviewer object by merging all form inputs
  //   const newReviewer = updatedForms.reduce((acc, form) => {
  //     return { ...acc, ...form };
  //   }, {});

  //   // Update the reviewer list with the new reviewer object
  //   const updatedReviewerList = [newReviewer];

  //   // Set the updated reviewer list back in formValues
  //   setFormValues({ ...formValues, reviewerList: updatedReviewerList });
  // };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedForms = [...reviewerForms];
    updatedForms[index] = { ...updatedForms[index], [name]: value };
    setReviewerForms(updatedForms);

    // Create a new reviewer object by merging all form inputs
    const newReviewer = updatedForms.reduce((acc, form) => {
      return { ...acc, ...form };
    }, {});

    // Update error states
    const { firstName, lastName, emailAddress } = newReviewer;
    let nameError = "";
    let emailError = "";
    if (!firstName && !lastName) {
      nameError = "Please enter at least First Name or Last Name!";
    }
    if (!emailAddress || !emailRegex.test(emailAddress)) {
      emailError = "Please add a valid email!";
    }

    // Update the reviewer list with the new reviewer object only if email is valid and either first name or last name is entered
    if (emailAddress && emailRegex.test(emailAddress) && (firstName || lastName)) {
      const updatedReviewerList = [newReviewer];
      setFormValues({ ...formValues, reviewerList: updatedReviewerList });
      setNameError("");
      setEmailError("");
    } else {
      setFormValues({ ...formValues, reviewerList: [] });
      setNameError(nameError);
      setEmailError(emailError);
    }
  };

  // const handleSubmit = () => {
  //   try {
  //     const {
  //       reviewerListIds = [], // Default to an empty array if reviewerListIds is undefined
  //       reviewerList = [], // Default to an empty array if reviewerList is undefined
  //     } = formValues;

  //     console.log(formValues?.reviewerList, "handleSubmit", formValues.reviewerListIds);

  //     if (
  //       (reviewerListIds.length === 0 && reviewerList.length === 0)
  //     ) {
  //       setErrorMessage("Please select at least one reviewer!");
  //       return;

  //     }
  //     if (nameError || emailError) {
  //       return;
  //     }
  //     // const { firstName = '', lastName = '', emailAddress = '', title = '' } = formValues;


  //     const reviewerData = {
  //       firstName,
  //       lastName,
  //       emailAddress,
  //       title,
  //     };

  //     const updatedReviewerList = Array.isArray(formValues.reviewerList) ? [...formValues.reviewerList, reviewerData] : [reviewerData];

  //     const payload = {
  //       jobApplicantVideoInterviewId,
  //       reviewerListIds: formValues.reviewerListIds || [],
  //       reviewerList: updatedReviewerList,
  //     };

  //     // Call the function passed from the parent component
  //     saveReviewer(payload);

  //     setFormValues({
  //       firstName: "",
  //       lastName: "",
  //       emailAddress: "",
  //       title: "",
  //       reviewerList: [], // Clear reviewerList
  //       reviewerListIds: [], // Clear reviewerListIds
  //     });
  //     setErrorMessage("");

  //   } catch (error) {
  //     console.error("Error processing reviewerList:", error);
  //   }
  // };


  const handleSubmit = () => {
    try {
      const {
        reviewerListIds = [], // Default to an empty array if reviewerListIds is undefined
        reviewerList = [], // Default to an empty array if reviewerListIds is undefined
      } = formValues;

      console.log(formValues?.reviewerList, "handleSubmit", formValues.reviewerListIds);

      if (!openForm && (reviewerListIds.length === 0 && reviewerList.length === 0)) {
        setErrorMessage("Please select at least one reviewer!");
        return;
      }

      if (nameError || emailError) {
        return;
      }

      const payload = {
        jobApplicantVideoInterviewId,
        reviewerListIds: formValues.reviewerListIds || [],
        reviewerList: formValues.reviewerList,
      };

      // Call the function passed from the parent component
      saveReviewer(payload);

      setFormValues({
        firstName: "",
        lastName: "",
        emailAddress: "",
        title: "",
        reviewerList: [], // Clear reviewerList
        reviewerListIds: [], // Clear reviewerListIds
      });
      setErrorMessage("");

    } catch (error) {
      console.error("Error processing reviewerList:", error);
    }
  };

  return (


    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Select Reviewers"
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
    >

      <div className="forms-input">
        <CheckboxList
          allData={allData}
          setAllData={setAllData}
          showSaveContainer={false}
          handleChange={handleChange}
        />
        <span className="error_msg">{formErrors.reviewerListIds}</span>
      </div>
      {/* <Button onClick={handleAddReviewer}>Add Reviewer</Button> */}
      <div>
        {errorMessage && <p className="error-msg">{errorMessage}</p>}
      </div>
      <span className="participants pointer" onClick={handleAddReviewer}>
        <AddIcon fontSize="small" /> Add Reviewer
      </span>

      {reviewerForms.map((form, index) => (
        <div className="add-upload-container" key={index}>
          <div className="text-right">
            <IconButton
              size="small"
              onClick={handleClose}
            >
              <CloseIcon color="primary" fontSize="small" />
            </IconButton>
          </div>
          <div className="forms-input sections">
            <div className="col-2">
              <label>First Name:</label>
              <TextField
                name="firstName"
                value={form.firstName}
                onChange={(event) => handleInputChange(event, index)}
                required
              />
              {nameError && <span className="error_msg">{nameError}</span>}
            </div>

            <div className="col-2">
              <label>Last Names:</label>
              <TextField
                name="lastName"
                value={form.lastName}
                onChange={(event) => handleInputChange(event, index)}
                required
              />
            </div>
          </div>

          <div className="forms-input sections">
            <div className="col-2">
              <label>Title:</label>
              <TextField
                name="title"
                value={form.title}
                onChange={(event) => handleInputChange(event, index)}
              />
            </div>

            <div className="col-2">
              <label>Email:</label>
              <TextField
                name="emailAddress"
                value={form.emailAddress}
                onChange={(event) => handleInputChange(event, index)}
                required
              />
              {emailError && <span className="error_msg">{emailError}</span>}

            </div>
          </div>
          {/* <IconButton onClick={handleSaveReviewer}>
            <CloseIcon />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton> */}
        </div>
      ))}
      {formErrors.reviewerListIds && (
        <div className="forms-input">
          <span className="error_msg">{formErrors.reviewerListIds}</span>
        </div>
      )}

      {allData.length === 0 && (
        <div className="forms-input">
          <span>Please add reviewers in your company</span>
        </div>
      )}

    </AddEditCol>
  );
};
